<template>
    <div>
        <ViewTemplateWithTable :panel="panel" :templateList="templateList" :filters="filters" :buttonRemove="true">
            <div slot="content-buttons">
                <Button _key="btnMovementCreate" title="Movimentação" classIcon="fas fa-plus-circle" type="primary"
                    size="small" :clicked="create" />
            </div>
        </ViewTemplateWithTable>

        <SideBar title="Movimentação">
            <div v-if="sideBar.open">
                <MovementBulkCreateUpdate />
            </div>
        </SideBar>
    </div>
</template>

<script>

import ViewTemplateWithTable from "@nixweb/nixloc-ui/src/component/template/ViewTemplateWithTable.vue";
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import SideBar from "@nixweb/nixloc-ui/src/component/forms/SideBar";

import MovementBulkCreateUpdate from '../../../components/modules/operational/movement-bulk/MovementBulkCreateUpdate.vue'

import { mapMutations, mapGetters, mapState } from "vuex";

export default {
    name: "FunnelView",
    components: { ViewTemplateWithTable, Loading, Button, Modal, SideBar, MovementBulkCreateUpdate },
    data() {
        return {
            panel: {
                module: "Operacional",
                title: "Movimentação em Massa",
                showFilter: true,
                showSearch: true,
                showButtons: true,
            },
            templateList: {
                urlGetApi: "/api/v1/operational/movement-bulk/get-all",
                urlDeleteAllApi: "/api/v1/operational/movement-bulk/delete",
                showChecks: true,
                headerTable: [
                    {
                        title: "Data",
                        field: "registeredDate",
                        type: "date",
                    },
                    {
                        field: "name",
                        title: "Identificação",
                        type: "link",
                        styleBody: "max-width: 300px;white-space: nowrap; overflow: hidden;text-overflow: ellipsis;",
                        eventName: "movementCreateUpdate",
                        iconSearch: true,
                    },
                    {
                        field: "customerSupplierName",
                        title: "Cliente/Locação",
                        type: "text",
                        styleBody: "max-width: 300px;white-space: nowrap; overflow: hidden;text-overflow: ellipsis;",
                        eventName: "movementCreateUpdate",
                       
                    },
                    {
                        field: "typeName",
                        title: "Tipo",
                        type: "html",
                    },
                    {
                        field: "status",
                        title: "Status",
                        type: "html",
                    },
                ],
            },
        }
    },
    mounted() {
        this.removeLoading(["panel"]);
    },
    computed: {
        ...mapGetters("generic", ["showModal", "event"]),
        ...mapState("generic", ["sideBar"]),
    },
    methods: {
        ...mapMutations("generic", ["addEvent", "openSideBar", "openModal", "removeLoading"]),
        create() {
            this.openSideBar();
            this.openModal("movement");
            this.addEvent({ name: "movementBulkCreate" });
            this.removeLoading(["btnMovementCreate"]);
        }
    },
    watch: {
        event: {
            handler(event) {
                if (event.name == "movementBulkUpdate") {
                    this.openModal("movement");
                }

                if (event.name == "movementCreateUpdate") {
                    this.$router.push({
                        name: "movementBulkUpdate",
                        params: { id: event.data.id },
                    });
                }
            },
            deep: true,
        },
    },
}
</script>
<style>
.bulk-box {
    width: 10px;
    height: 10px;
    border-radius: 50px;
    margin-right: 8px;
}

.finished {
    background-color: darkblue;
}

.pending {
    background-color: red;
}

.icon-output {
    color: #009183;
    font-size: 15px;
}

.icon-return {
    color: #B8860B;
    font-size: 15px;
}
</style>